<template>
	<div class="ybd-view">
		<ucenterHeader ></ucenterHeader>
		<div class="container">
			<ucenterLeftNav></ucenterLeftNav>
			<div class="main">
				<div class="u-rightbar">
					<div class="module-title"><i></i><span>我的表单</span></div>
					<div class="main-operate clearfix">
						<div class="tab-display fl">
							<i class="i-ico i-block" :class="{cur:tabCurrent == 0}" @click="tabCurrent = 0"></i>
							<span></span>
							<i class="i-ico i-list" :class="{cur:tabCurrent == 1}" @click="tabCurrent = 1"></i>
						</div>
						<div class="fl select-box">
							<el-select v-model="listParams.fromType" size="small" @change="search">
								<el-option value="" label="全部类型"></el-option>
								<el-option v-for="item in fromTypeList" :key="item.value" :value="item.value" :label="item.name"></el-option>
							</el-select>
						</div>
						<div class="fl search-box">
							<el-input v-model="listParams.title" class="search-input" placeholder="搜索" size="small"></el-input>
							<a class="i-ico i-search" href="javascript: void(0);" @click="search"></a>
						</div>
						<div class="fl btns-box"><router-link to="/" class="b-btn"><el-button size="small" type="primary">新建项目</el-button></router-link></div>
						<div class="fr link-box">
							<el-button size="small" @click="backups(this);"><span>一键备份</span></el-button>
							<el-button size="small" @click="formRestore(this);"><span>一键还原</span></el-button>
							<el-button size="small" @click="recycleBin(this);"><span>回收站</span></el-button>
						</div>
					</div>
					<transition name="fade">
						<div class="c-questionnaire" v-if="tabCurrent == 0">
							<template v-if="list.length > 0">
								<ul class="questionnaire-list clearfix">
									<li v-for="item in list" :key="item._id">
										<div class="item-inner">
											<template v-if="item.status != 2">
												<div class="item-tip tip-ques" v-if="item.fromType == 'ques'">问卷</div>
												<div class="item-tip tip-form" v-else-if="item.fromType == 'form'">表单</div>
												<div class="item-tip tip-exam" v-else-if="item.fromType == 'exam'">测评</div>
												<div class="item-imgbox"><img src="/form/ucenter/img/form_default_img.jpg" alt=""></div>
												<dl class="item-content">
													<dt class="ellipsis">{{item.title}}</dt>
													<dd>{{item.createTime}}</dd>
												</dl>
												<div class="item-info clearfix">
													<div class="fl">{{item.date_count}}份数据</div>
													<div class="font-orange" v-if="item.status == 1"><i></i><span>未发布</span></div>
													<div class="font-green" v-else><i></i><span>收集中</span></div>
												</div>
											</template>
											<template v-else>
												<div class="item-tip tip-ques" v-if="item.fromType == 'ques'">问卷</div>
												<div class="item-tip tip-form" v-else-if="item.fromType == 'form'">表单</div>
												<div class="item-tip tip-exam" v-else-if="item.fromType == 'exam'">测评</div>
												<div class="item-imgbox cursor" @click="detail(item._id,item.fromType)"><img src="/form/ucenter/img/form_default_img.jpg" alt=""></div>
												<dl class="item-content cursor" @click="detail(item._id,item.fromType)">
													<dt class="ellipsis">{{item.title}}</dt>
													<dd>{{item.createTime}}</dd>
												</dl>
												<div class="item-info clearfix">
													<div class="fl">{{item.date_count}}份数据</div>
													<div class="font-orange" v-if="item.status == 1"><i></i><span>未发布</span></div>
													<div class="font-green" v-else><i></i><span>收集中</span></div>
												</div>
											</template>
											<div class="item-operate">
												<ul class="clearfix">
													<li><a href="javascript: void(0);" @click="editForm(item._id,item.fromType)"><i class="i-ico i-edit"></i><span>编辑</span></a></li>
													<li v-if="item.status != 2"><a href="javascript: void(0);" @click="publishForm(item._id,item.fromType)"><i class="i-ico i-publish"></i><span>发布</span></a></li>
													<li v-else><a href="javascript: void(0);" @click="publishStop(item._id)"><i class="i-ico i-publish"></i><span>暂停发布</span></a></li>
													<li><a href="javascript: void(0);" @click="dateList(item._id,item.fromType);"><i class="i-ico i-data"></i><span>数据</span></a></li>
													<li>
														<a href=""><i class="i-ico i-more"></i><span>更多</span></a>
														<dl>
															<dd><a href="javascript: void(0);" @click="browseForm(item._id,item.fromType)">预览项目</a></dd>
															<dd><a href="javascript: void(0);" @click="fromCopy(item._id,item.title);">复制项目</a></dd>
															<dd><a href="javascript: void(0);" @click="fromExport(item._id);">导出项目</a></dd>
															<dd><a href="javascript: void(0);" @click="addRecycleBin(item._id);">删除项目</a></dd>
															<!-- <dd v-if="item.AdvState == 1"><a href="javascript: void(0);" @click="wipeAdvertising(item._id);">去广告</a></dd> -->
														</dl>
													</li>
												</ul>
											</div>
										</div>
									</li>
								</ul>
								<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
							</template>
							<noData v-else text="暂时还没有表单哦！"></noData>
						</div>
					</transition>
					<transition name="fade">
						<div v-if="tabCurrent == 1">
							<template v-if="list.length > 0">
								<el-table :data="list" class="form-table" border style="width: 100%">
									<el-table-column prop="title" label="标题"></el-table-column>
									<el-table-column prop="fromType" label="类型" width="120">
										<template slot-scope="scope">
											{{scope.row.fromType | fromTypeName}}
										</template>
									</el-table-column>
									<el-table-column prop="status" label="收集状态" width="120">
										<template slot-scope="scope">
											<div class="font-orange" v-if="scope.row.status == 1"><i></i><span>未发布</span></div>
											<div class="font-green" v-else><i></i><span>收集中</span></div>
										</template>
									</el-table-column>
									<el-table-column prop="date_count" label="收到数据" width="120"></el-table-column>
									<el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
									<el-table-column prop="update_time" label="更新时间" width="180">
										<template slot-scope="scope">
											{{scope.row.update_time | dateTimeFormat}}
										</template>
									</el-table-column>
									<el-table-column fixed="right" label="操作" width="200">
										<template slot-scope="scope">
											<a href="javascript: void(0);" class="operate-btn" @click="editForm(scope.row._id,scope.row.fromType)"><i class="i-ico i-edit"></i><span>编辑</span></a>
											<a href="javascript: void(0);" class="operate-btn" @click="publishForm(scope.row._id,scope.row.fromType)" v-if="scope.row.status != 2"><i class="i-ico i-publish"></i><span>发布</span></a>
											<a href="javascript: void(0);" class="operate-btn" @click="publishStop(scope.row._id)" v-else><i class="i-ico i-publish"></i><span>暂停发布</span></a>
											<el-dropdown class="operate-btn">
												<el-button type="text" size="small" class="el-dropdown-link"><i class="i-ico i-more"></i></el-button>
												<el-dropdown-menu slot="dropdown">
													<el-dropdown-item @click="dateList(scope.row._id,scope.row.fromType)">项目数据</el-dropdown-item>
													<el-dropdown-item @click="browseForm(scope.row._id,scope.row.fromType)">预览项目</el-dropdown-item>
													<el-dropdown-item @click="fromCopy(scope.row._id,scope.row.title);">复制项目</el-dropdown-item>
													<el-dropdown-item @click="fromExport(scope.row._id);">导出项目</el-dropdown-item>
													<el-dropdown-item @click="addRecycleBin(scope.row._id);">删除项目</el-dropdown-item>
													<!-- <el-dropdown-item v-if="scope.row.AdvState == 1" @click="wipeAdvertising(scope.row._id);">去广告</el-dropdown-item> -->
												</el-dropdown-menu>
											</el-dropdown>
										</template>
									</el-table-column>
								</el-table>
								<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
							</template>
							<noData v-else text="暂时还没有数据哦！"></noData>
						</div>
					</transition>
				</div>
				<el-dialog title="回收站" :visible.sync="dialogVisible" width="900px">
					<template v-if="recycleList.length > 0">
						<el-table :data="recycleList" class="form-table" border style="width: 100%">
							<el-table-column property="title" label="标题"></el-table-column>
							<el-table-column property="fromType" label="类型" width="70">
								<template slot-scope="scope">
									{{scope.row.fromType | fromTypeName}}
								</template>
							</el-table-column>
							<el-table-column property="createTime" label="创建时间" width="170"></el-table-column>
							<el-table-column property="delete_time" label="删除时间" width="170"></el-table-column>
							<el-table-column property="address" label="操作" width="200">
								<template slot-scope="scope">
									<a href="javascript: void(0);" class="operate-btn" @click="regainDate(scope.row._id)"><i class="i-ico i-restore"></i><span>恢复数据</span></a>
									<a href="javascript: void(0);" class="operate-btn" @click="deleteForm(scope.row._id)"><i class="i-ico i-delete"></i><span>彻底删除</span></a>				
								</template>
							</el-table-column>
						</el-table>
						<el-pagination @size-change="recyclePageSize" @current-change="recyclePageCurrent" :current-page="recycle.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="recycle.pageSize" layout="total, prev, pager, next, jumper" :total="recycleCount" prev-text="上一页" next-text="下一页" background></el-pagination>
					</template>
					<noData v-else text="暂时还没有数据哦！"></noData>
					<div slot="footer" class="dialog-footer">
						<el-button @click="dialogVisible = false">取 消</el-button>
						<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
					</div>
				</el-dialog>
				<el-dialog title="去广告" :visible.sync="advVisible" width="460px">
					<template>
						<div class="remove_technical_nav">
							<a :class="{active :technicalNav==0}" href="javascript: void(0);" @click="technicalNav=0">广告券</a>
							<a :class="{active :technicalNav==1}" href="javascript: void(0);" @click="technicalNav=1" class="">去付款</a>
						</div>
						<div class="remove_technical_main">
							<!-- 广告券 -->
							<div class="remove_technical_item" v-if="technicalNav==0">
								<div class="remain_coupons">您的账户还剩<span>{{technical.adCouponNum}}</span>张去广告券</div>
								<img class="coupons_img" src="@/assets/images/coupons_img.png" alt="">
								<a class="user_btn" :class="{disable:technical.adCouponNum==0}" href="javascript: void(0);" @click="useAdvCoupon(technical.formId);">立即使用</a><!-- disable -->
							</div>
							<!-- 去付款 -->
							<div class="remove_technical_item" v-else>
								<div class="coupons_bug">
									<span>购买去广告券</span>
									<span class="coupons_price">¥<b>{{technical.couponPrice}}</b></span>
								</div>
									<a class="user_btn" href="javascript: void(0);" @click="goToUcenterRecharge();">立刻购买</a>
							</div>
						</div>
					</template>
				</el-dialog>
			</div>
		</div>
	</div>
</template>
<script>
import { get,post } from "/src/services/ajax_ucenter.js";
import ucenterHeader from "@/components/ucenterHeader.vue";
import ucenterLeftNav from "@/components/ucenterLeftNav.vue";
import noData from "@/components/noData";
export default {
	components: {
		ucenterHeader,
		ucenterLeftNav,
		noData
	},
	data(){
		return {
			backupsTime:'',
			tabCurrent:0,
			fromTypeList: [
				{value:'ques',name:'问卷'},
				{value:'exam',name:'测评'},
				{value:'vote',name:'投票'},
				{value:'form',name:'表单'}
			],
			list:[],
			listParams:{
				fromType:'',
				title:'',
				pageNo:1,
				pageSize:20
			},
			count: 0,
			recycleList:[],
			recycle:{
				pageNo:1,
				pageSize:10,
			},
			recycleCount:0,
			dialogVisible:false,
			technicalNav:0,
			advVisible:false,
			technical:{}
		}
	},
	created(){
		
	},
	filters: {
		fromTypeName(val){
			let name = '';
			if(val == 'ques'){
				name = '问卷'
			}else if(val == 'exam'){
				name = '测评'
			}else if(val == 'vote'){
				name = '投票'
			}else if(val == 'form'){
				name = '表单'
			}
			return name;
		}
	},
	watch:{
	},
	mounted(){
		this.getTplList();
	},
	methods: {
		// 切换列表
		search(){
			const _this = this;
			_this.listParams.pageNo = 1;
			_this.getTplList();
		},
		// 获取模板列表
		getTplList(){
			const _this = this;
			get('/formadmin/myFrom.jhtml',_this.listParams).then(res => {
				if(res.code == 200){
					_this.count = res.data.count;
					_this.list = res.data.date;
					_this.backupsTime = res.data.userBean.backupsTime;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		pageCurrentChange(parm){
			this.listParams.pageNo = parm;
			this.getTplList();
		},
		pageSizeChange(parm){
			this.listParams.pageSize = parm;
			this.getTplList();
		},
		// 编辑表单
		editForm(formId,fromType){
			const _this = this;
			this.$confirm('修改后您收集的关于该表单的所有数据将背删除！确定继续？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/stop_publish.jhtml',{formId}).then(res => {
					if(res.code == 200){
						let url = '';
						if(fromType == 'form'){
							url = '/questionTypes';
						}else if(fromType == 'exam'){
							url = '/exam_questionTypes';
						}else{
							url = '/ques_questionTypes';
						}
						_this.$router.push({
							path:url,
							query:{
								formId
							}
						})
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
			
		},
		// 表单发布
		publishForm(id,fromType){
			const _this = this;
			this.$confirm('您确定要发布项目吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/form_publish.jhtml',{formId:id}).then(res => {
					if(res.code == 200){
						_this.$message.success('发布成功！');
						_this.$router.push({
							path:'/share',
							query:{
								formId:id,
								fromType
							}
						})
						// _this.getTplList();
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
			
		},
		// 表单暂停发布
		publishStop(id){
			const _this = this;
			post('/formadmin/stop_publish.jhtml',{formId:id}).then(res => {
				if(res.code == 200){
					_this.$message.success('暂停发布成功！');
					_this.getTplList();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 表单数据
		dateList(id,fromType){
			const _this = this;
			let url = '';
			if(fromType == 'form'){
				url = '/formData';
			}else if(fromType == 'ques'){
				url = '/quesData';
			}else if(fromType == 'exam'){
				url = '/examData';
			}else if(fromType == 'vote'){
				url = '/statics';
			}
			_this.$router.push({
				path:url,
				query:{
					id
				}
			})
		},
		// 表单预览
		browseForm(id,fromType){
			const _this = this;
			let url = '';
			if(fromType == 'form'){
				url = '/preview';
			}else if(fromType == 'exam'){
				url = '/exam_preview';
			}else{
				url = '/ques_preview';
			}
			_this.$router.push({
				path:url,
				query:{
					formId:id
				}
			})
		},
		// 发布后表单详情
		detail(id,fromType){
			const _this = this;
			_this.$router.push({
				path:'/share',
				query:{
					formId:id,
					fromType
				}
			})
		},
		// 复制表单
		fromCopy(id,title){
			const _this = this;
			post('/formadmin/form_copy.jhtml',{formId:id,title}).then(res => {
				if(res.code == 200){
					_this.getTplList();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 导出
		fromExport(id){
			// const _this = this;
			window.location.href = '/formadmin/word_download.jhtml?formId='+id;
			// get('/formadmin/word_download.jhtml',{formId:id}).then(res => {
			// 	console.log(res)
			// 	if(res.code == 200){
			// 		console.log(id)
			// 	}else{
			// 		_this.$message.error(res.message);
			// 	}
			// }).catch(err => {
			// 	_this.$message.error(err);
			// })
		},
		// 去广告
		wipeAdvertising(id){
			const _this = this;
			get('/formadmin/wipe_advertising.jhtml',{formId:id}).then(res => {
				if(res.code == 200){
					_this.advVisible = true;
					_this.technical = res.data;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 使用去广告券
		useAdvCoupon(formId){
			const _this = this;
			post('/formadmin/submit_advertising.jhtml',{formId}).then(res => {
				if(res.code == 200){
					_this.$message.success("成功去除广告！");
					_this.advVisible = false;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 购买去广告券
		goToUcenterRecharge(){
			const _this = this;
			post('/formadmin/create_order.jhtml',{}).then(res => {
				if(res.code == 200){
					window.location.href = res.data.payUrl;
					_this.advVisible = false;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 删除进入回收站
		addRecycleBin(id){
			const _this = this;
			this.$confirm('您是确定删除？删除后项目将移入回收站，可进行恢复。', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/add_recycle_bin.jhtml',{id}).then(res => {
					if(res.code == 200){
						_this.getTplList();
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
		},
		// 一键备份
		backups(){
			const _this = this;
			post('/formadmin/form_backups.jhtml',{}).then(res => {
				if(res.code == 200){
					_this.$message.success('备份成功！');
					_this.backupsTime = res.data.backupsTime;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 一键还原
		formRestore(){
			const _this = this;
			this.$confirm('您是否恢复'+_this.backupsTime+'时候的数据？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/form_restore.jhtml',{}).then(res => {
					if(res.code == 200){
						_this.$message.success('还原成功');
						_this.getTplList();
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
		},
		// 打开回收站
		recycleBin(){
			const _this = this;
			_this.dialogVisible = true;
			_this.getRecycleList();		
		},
		// 获取回收站列表
		getRecycleList(){
			const _this = this;
			get('/formadmin/my_recycle_bin.jhtml',_this.recycle).then(res => {
				if(res.code == 200){
					_this.recycleCount = res.data.count;
					_this.recycleList = res.data.date;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		recyclePageCurrent(parm){
			this.recycle.pageNo = parm;
			this.getRecycleList();
		},
		recyclePageSize(parm){
			this.recycle.pageSize = parm;
			this.getRecycleList();
		},
		// 恢复数据
		regainDate(id){
			const _this = this;
			post('/formadmin/recycle_regain_date.jhtml',{ids:id}).then(res => {
				if(res.code == 200){
					_this.getTplList();
					_this.getRecycleList();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 彻底删除
		deleteForm(id){
			const _this = this;
			this.$confirm('你确定要永久删除吗？删除后无法恢复', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/recycle_delete.jhtml',{ids:id}).then(res => {
					if(res.code == 200){
						_this.getRecycleList();
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
		},

	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.u-rightbar {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 20px 30px;
		background: #fff;
		.module-title{
			font-size: 16px;
			line-height: 36px;
			color:#333;
			margin-bottom: 10px;
			padding:0;
			border-bottom:none; 
			i{
				width: 4px;
				height:16px;
				background: var(--theme-color);
				margin-right: 10px;
				display: inline-block;
				vertical-align:-2px;
			}
		}
		.i-ico {
			width: 20px;
			height: 20px;
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
			background:url("../../../assets/images/icon_main.png") -25px -246px no-repeat;
		}
		.main-operate {
			margin-bottom: 20px;
			.tab-display {
				border: 1px solid #e5e5e5;
				background: #fff;
				padding: 4px 10px;
				margin-right: 7px;
				border-radius: 2px;
				.i-block {
					background-position: -238px 2px;
				}
				.i-block.cur {
					background-position: -238px -28px;
				}
				.i-list {
					background-position: -268px 2px;
				}
				.i-list.cur {
					background-position: -268px -28px;
				}
				span {
					height: 12px;
					width: 0;
					display: inline-block;
					border-left: 1px solid #d8d8d8;
					margin: 0 6px;
					vertical-align: middle;
				}
			}
			.select-box {
				margin-right: 7px;
				min-width: 100px;
			}
			.search-box {
				margin-right: 7px;
				width:250px;
				position: relative;
				padding: 0;
				.search-input {
					line-height: 28px;
					font-size: 14px;
				}
				.i-search {
					width: 20px;
					height: 20px;
					background-position: -327px -87px;
					position: absolute;
					right: 10px;
					top: 5px;
				}
			}
		}
		
		.questionnaire-list {
			// min-height: 400px;
			margin: 0 -10px;
			display: flex;
			flex-wrap: wrap;
			li {
				float: left;
				width: 244px;
				box-sizing: border-box;
				padding: 12px 10px;
				.item-inner {
					border: 1px solid #e5e5e5;
					border-radius: 4px;
					position: relative;
					background: #fff;
					margin: 1px;
					.item-imgbox {
						height: 120px;
						position: relative;
						background: #f5f5f5 url(../../../assets/images/form_default_img.jpg) no-repeat center;
						img {
							max-width: 100%;
							max-height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							margin: auto;
						}
					}
					.item-content {
						padding: 10px 14px;
						dt {
							font-size: 14px;
							line-height: 24px;
							color: #333;
						}
						dd {
							font-size: 12px;
							line-height: 20px;
							color: #999;
						}
					}
					.cursor{
						cursor: pointer;
					}
					.item-info {
						padding: 7px 17px;
						font-size: 14px;
						line-height: 30px;
						display: flex;
						justify-content: space-between;
						align-items: center;
					}
					.item-operate {
						background: #f7f8fa;
						border-top: 1px solid #e5e5e5;
						height: 43px;
						display: none;
						border-bottom-left-radius: 2px;
						border-bottom-right-radius: 2px;
						ul {
							display: flex;
							justify-content: space-between;
							align-items: center;
							li {
								width: 25%;
								text-align: center;
								padding: 2px 0 5px;
								position: relative;
								span {
									display: block;
									font-size: 12px;
									color: #999;
									line-height: 16px;
								}
								.i-edit {
									background-position: 2px -118px;
								}
								.i-publish {
									background-position: -29px -119px;
								}
								.i-data {
									background-position: -58px -117px;
								}
								.i-putin {
									background-position: -88px -118px;
								}
								.i-more {
									background-position: -117px -118px;
								}
								dl {
									position: absolute;
									left: 15px;
									top: 43px;
									z-index: 2;
									width: 80px;
									border-radius: 2px;
									background: #fff;
									border: 1px solid #e5e5e5;
									display: none;
									dd a {
										display: block;
										line-height: 35px;
										font-size: 12px;
										color: #666;
										&:hover {
											background: #f5f6fa;
										}
									}
								}
								&:hover{
									dl{
										display: block;
									}
								}
							}
						}
					}
					&:hover {
						border: 2px solid #0cf;
						margin: 0;
						box-shadow: 0 0 16px 0 rgba(6,136,168,0.18);
						.item-info {
							display: none;
						}
						.item-operate{
							display: block;
						}
					}
					.item-tip {
						position: absolute;
						top: 0;
						left: 17px;
						z-index: 1;
						font-size: 12px;
						line-height: 20px;
						padding: 0 8px;
						color: #fff;
						border-bottom-left-radius: 2px;
						border-bottom-right-radius: 2px;
					}

					.item-tip.tip-test {
						background: #f79254;
					}

					.item-tip.tip-form {
						background: #09db9d;
					}

					.item-tip.tip-ques,.item-tip.tip-vote {
						background: #0cf;
					}

				}
			}
		}
	}
	::v-deep .el-dialog{
		.el-dialog__header{
			border-bottom:1px solid #DCDFE6;
		}
		.el-dialog__body{
			height: 360px;
			overflow-y: auto;
			.remove_technical_nav{
				display: flex;
				a{
					flex: 1;
					height: 40px;
					line-height: 40px;
					border: 1px solid #e5e5e5;
					text-align: center;
					font-size: 16px;
					background-color: #f4f5f9;	
					&:first-child{
						border-right:none;
					}
				}
				.active{
					color:var(--theme-color);
					background-color: #fff;
				}
			}
			.remove_technical_item{
				.remain_coupons{
					line-height: 60px;
					text-align: center;
					font-size: 16px;
					color: #999;
					span{
						margin: 0 3px;
						font-size: 18px;
						color: #f80;
						font-weight: bold;
					}
				}
				.coupons_img{
					display: block;
					width: 298px;
					height: 129px;
					margin: 0 auto 20px;
				}
				.coupons_img.disable{filter: grayscale(1);}
				.user_btn{
					display: block;
					width: 256px;
					height: 36px;
					line-height: 34px;
					border: 1px solid #0cf;
					margin: 0 auto;
					background-color: #0cf;
					color: #fff;border-radius: 3px;
					text-align: center;font-size: 16px;
					&:hover{
						color: #0cf;
						background: #fff;
					}
				}
				.disable{filter: grayscale(1);pointer-events: none;}
				.current_balance{
					margin: 30px 0 10px;
					text-align: center;
					color: #999;
					font-size: 16px;
					span{
						color: #f80;
					}
					b{
						margin: 0 3px;
						font-size: 34px;
						font-weight: bold;
					}
				}
				.recharge_tips{
					width: 162px;
					height: 28px;
					line-height: 26px;
					border: 1px solid #0cf;
					margin: 0 auto;border-radius: 30px;
					text-align: center;color: #0cf;
					font-size: 12px;
				}
				.coupons_bug{
					display: flex;
					width: 320px;
					height: 52px;
					line-height: 52px;
					padding: 0 20px;
					border: 1px solid #e5e5e5;
					margin: 30px auto 38px;
					border-radius: 3px;
					background: #fafafa;
					justify-content: space-between;
					font-size: 16px; 
					.coupons_price{
						color: #f80;
						b{
							margin: 0 0 0 3px;
							font-size: 24px;
						} 
					}
				}
				.technical_num{
					margin: 40px 0 0;
					text-align: center;
					font-size: 18px;
					span{
						display: inline-block;
						padding: 0 3px;
						color: #f80;
					}
				}
				.technical_btn a{
					display: block;
					width: 150px;
					height: 36px;
					line-height: 34px;
					border: 1px solid #0cf;
					margin: 25px auto 0;
					text-align: center;
					border-radius: 4px;background: #0cf;
					color: #fff;
					&:hover{color: #0cf;background-color: #fff;}
				}
			}
		}
	}
	.form-table{
		.i-ico{
			width:20px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			background-image: url(../../../assets/images/ico_table.png);
		}
		.operate-btn{
			span{
				vertical-align: middle;
			}
			.i-edit{background-position: 3px 3px;}
			.i-publish{background-position: -28px 3px;}
			.i-more{background-position: -57px 2px;}
			.i-restore{background-position: -147px 2px;} /*恢复数据*/
			.i-delete{background-position: -87px 2px;}

			&:hover{
				color:var(--theme-color);
				.i-edit{background-position: 3px -27px;}
				.i-publish{background-position: -28px -27px;}
				.i-more{background-position: -57px -28px;}
				.i-restore{background-position: -147px -28px;}
				.i-delete{background-position: -87px -28px;}
			}
		}
		.operate-btn+.operate-btn{margin-left:15px;}
	} 
	
	::v-deep .el-pagination{
		padding: 20px 10px 10px;
		font-weight: normal;
		text-align: right;
		.el-pagination__total,
		.el-pagination__sizes{
			float: left;
			.el-input{
				.el-input__inner{
					&:hover{
						border-color: var(--theme-color);
					}
				}
			}
		}
		.el-pager{
			li:not(.disabled){
				&:hover{
					color: var(--theme-color);
				}
			}
			li:not(.disabled).active{
				background-color: var(--theme-color);
				&:hover{
					color: #fff;
				}
			}
		}
		.btn-prev,
		.btn-next{
			font-size: 12px;
			padding: 0 5px;
		}
	}	
		
}
</style>